import React from 'react'
import { useEffect, useState } from "react";
import { Button, Checkbox, DatePicker, Input, Table, notification, } from 'antd';
import { InfoCircleFilled, SmileOutlined } from '@ant-design/icons';
import { ADD_UPDATE_GRADE_PLAN, CALENDARS_LIST, LIST_STUDENT_HEALTH_INSURANCE, STUDENT_SERVICE_ADD_UPDATE, VIEW_GRADE_PLAN, sisDateWithShortMonth, } from "../../apis/apis";
import SisLoader from '../../widgets/loader';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

function GradePlan() {
    const dateFormat = 'MM-DD-YYYY';
    const navigate = useNavigate();
    const { calender_id, sem_id } = useParams();
    const history = useLocation();
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);
    const [calendars_list, set_calendars_list] = useState("");
    const [semesters_list, set_semesters_list] = useState("");
    const [single_semester, set_single_semester] = useState([]);
    // Permissions state manage 
    const [all_permissions, set_all_permissions] = useState(false);
    const [permissions, set_permissions] = useState([]);
    const check_permissions = (module_name, type) => {
        const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
        if (localStorgeData.role === 2074) {
            return true;
        }
        var check_permissions = [];
        if (type === 'create') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
        }
        if (type === 'update') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
        }
        if (type === 'status') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
        }
        if (type === 'delete') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
        }

        if (check_permissions.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    //const [student_id, set_student_id] = useState(20);
    const [active_calender_id, set_active_calender_id] = useState();
    const [active_sem_id, set_active_sem_id] = useState();
    const [table_list, set_table_list] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    //   Plan State

    const [dua_date, set_dua_date] = useState('');
    const [start_grading_date, set_start_grading_date] = useState('');
    const [end_grading_date, set_end_grading_date] = useState('');
    const [review_start_date, set_review_start_date] = useState('');
    const [review_end_date, set_review_end_date] = useState('');
    const [grade_access, set_grade_access] = useState(0);


    // Hello world

    const VIEW_API = async () => {
      
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("token", USER_DATA.token);
        FORM_DATA.append("semester_id", atob(sem_id));
        const API_RESPONSE = await VIEW_GRADE_PLAN(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            set_dua_date(API_RESPONSE?.data?.grade?.dua_date)
            set_start_grading_date(API_RESPONSE?.data?.grade?.start_grading_date)
            set_end_grading_date(API_RESPONSE?.data?.grade?.end_grading_date)
            set_review_start_date(API_RESPONSE?.data?.grade?.review_start_date)
            set_review_end_date(API_RESPONSE?.data?.grade?.review_end_date)
            set_grade_access(API_RESPONSE?.data?.grade?.grade_access)
            set_loader(false);
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false);
           
        }
    };
    const ADD_API = async () => {
        set_loader(true);
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("token", USER_DATA.token);
        FORM_DATA.append("semester_id", atob(sem_id));
        FORM_DATA.append("dua_date", dua_date);
        FORM_DATA.append("start_grading_date", start_grading_date);
        FORM_DATA.append("end_grading_date", end_grading_date);
        FORM_DATA.append("review_start_date", review_start_date);
        FORM_DATA.append("review_end_date", review_end_date);
        FORM_DATA.append("grade_access", grade_access);
        const API_RESPONSE = await ADD_UPDATE_GRADE_PLAN(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            notification.open({
                message: "Success!!",
                description: "Grade Plan saved successfully.",
                icon: <SmileOutlined style={{ color: "green" }} />,
            });
            set_loader(false);
            navigate('/grade-plan/' + calender_id + '/' + sem_id)
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false);
        }
    };



    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const Calendar_API_RESPONSE = await CALENDARS_LIST(FORM_DATA);

        if (Calendar_API_RESPONSE?.data?.status) {

            set_loader(false);
            set_calendars_list(Calendar_API_RESPONSE?.data?.calendars);
            if (!calender_id) {
                navigate('/grade-plan/' + btoa(Calendar_API_RESPONSE.data.calendars[0]?.id) + '/' + btoa(Calendar_API_RESPONSE.data.calendars[0]?.semesters[0]?.id))
            } else {
                var getCalender = Calendar_API_RESPONSE?.data?.calendars?.filter((item) => item.id == atob(calender_id));
                if (!sem_id) {
                    var seme = getCalender[0]?.semesters[0]?.id;
                    navigate('/grade-plan/' + calender_id + '/' + btoa(seme))
                } else {
                    set_semesters_list(getCalender[0]?.semesters);
                     var semester = getCalender[0]?.semesters?.filter((item) => item.id == atob(sem_id));
                     set_single_semester(semester[0]);
                    VIEW_API();
                }

            }
            // REGISTER_STUDENT_LIST_API(Calendar_API_RESPONSE.data.calendars[0]?.id, Calendar_API_RESPONSE.data.calendars[0]?.semesters[0]?.id);
        } else {
            set_loader(false);
        }
    }




    useEffect(() => {
        LIST_API();
    }, [history]);





    const generate_sem_list = (item) => {
        set_semesters_list(item.semesters);
        set_active_calender_id(item.id);
        console.log("sem list", item)
        console.log("sem active id", item.semesters[0]?.id)
        set_active_sem_id(item.semesters[0]?.id);
        console.log("hello", calendars_list[item.id]?.semesters[0].id)
    }

    const generate_students_list = (sem_detail) => {
        console.log("generate student list", sem_detail);
        set_active_sem_id(sem_detail?.id);
    }

    console.log("generate students list", single_semester);
    return (
        <>
        {loader ? <>
        <div style={{ marginTop:"450px" }}>
        <SisLoader  />
        </div>
         
        </> : <>
        <div className="theme-content-left-head">
                <h3>Grade Plan</h3>
            </div>
            <div className="theme-content-head">
                <div className="sis-tab-menu">
                    {calendars_list.length > 0 && (
                        <>
                            {calendars_list.map((item, index) => (
                                <>
                                    <>
                                        {(atob(calender_id) == item.id) ?
                                            <>
                                                <span className="active" >{item.title} </span>
                                            </>
                                            :
                                            <>
                                                <span onClick={() => window.location = '/grade-plan/' + btoa(item.id)} >{item.title}</span>
                                            </>
                                        }
                                    </>
                                </>
                            ))}
                        </>
                    )}
                </div>

            </div>
            <div className="sis-tab-sem-menu">

                {semesters_list?.length > 0 &&
                    <>

                        {semesters_list.map((item, index) => (
                            <>
                                <>
                                    {(atob(sem_id) == item.id) ?
                                        <>
                                            <span className="active" >{item.title} </span>
                                        </>
                                        :
                                        <>
                                            <span onClick={() => navigate('/grade-plan/' + calender_id + '/' + btoa(item.id))} >{item.title}</span>
                                        </>
                                    }
                                </>
                            </>
                        ))}
                    </>
                }
            </div>
            <div>
                <div className="sis-tab-content" style={{ marginTop: "50px" }}  >
                    {loader ? (
                        <SisLoader />
                    ) : (
                        <>
                         {semesters_list?.length > 0 ? <>
                            <h3 style={{ marginBottom: "20px" }}>Marking Period</h3>
                            <div className='five-column-row'>
                                <div>
                                    <label>Semester period</label>
                                    <h4>{sisDateWithShortMonth(single_semester?.start_date)} - {sisDateWithShortMonth(single_semester?.end_date)}</h4>
                                </div>
                                <div>
                                    <label>Plan description</label>
                                    <h4>Final Grade entry</h4>
                                </div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                            {(check_permissions('grade_plan', 'create') || check_permissions('grade_plan', 'update'))&&
                            <div className='five-column-row' style={{ marginTop: "20px" }}>
                                <div>
                                    <label>Due Date<i style={{ color: "red" }}>*</i></label>
                                    <DatePicker format={dateFormat}
                                        value={dua_date && dayjs(dua_date)}
                                        onChange={(date, dateString) =>
                                            set_dua_date(dateString)
                                        }
                                        style={{ width: "100%" }}
                                    />
                                    {errors?.dua_date && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.dua_date[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div>
                                    <label>Grading start date<i style={{ color: "red" }}>*</i></label>
                                    <DatePicker format={dateFormat}
                                        value={start_grading_date && dayjs(start_grading_date)}
                                        onChange={(date, dateString) =>
                                            set_start_grading_date(dateString)
                                        }
                                        style={{ width: "100%" }}
                                    />
                                    {errors?.start_grading_date && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.start_grading_date[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div>
                                    <label>Grading end date<i style={{ color: "red" }}>*</i></label>
                                    <DatePicker format={dateFormat}
                                        value={end_grading_date && dayjs(end_grading_date)}
                                        onChange={(date, dateString) =>
                                            set_end_grading_date(dateString)
                                        }
                                        style={{ width: "100%" }}
                                    />
                                    {errors?.end_grading_date && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.end_grading_date[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div>
                                    <label>Review start date<i style={{ color: "red" }}>*</i></label>
                                    <DatePicker format={dateFormat}
                                        value={review_start_date && dayjs(review_start_date)}
                                        onChange={(date, dateString) =>
                                            set_review_start_date(dateString)
                                        }
                                        style={{ width: "100%" }}
                                    />
                                    {errors?.review_start_date && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.review_start_date[0]}
                                            </span>
                                        </>
                                    )}
                                </div>
                                <div>
                                    <label>Review end date<i style={{ color: "red" }}>*</i></label>
                                    <DatePicker format={dateFormat}
                                        value={review_end_date && dayjs(review_end_date)}
                                        onChange={(date, dateString) =>
                                            set_review_end_date(dateString)
                                        }
                                        style={{ width: "100%" }}
                                    />
                                    {errors?.review_end_date && (
                                        <>
                                            <span style={{ color: "red" }}>
                                                {errors?.review_end_date[0]}
                                            </span>
                                        </>
                                    )}
                                </div>

                            </div>}
                            <h3 style={{ marginBottom: "20px", marginTop: "50px" }}>Grade Access</h3>
                            <div className="Lu_sis_">
                                <div className='theme-content-head' style={{ marginBottom: '0px' }}>
                                    <div className='theme-content-left-head' >
                                        <span style={{ paddingRight: "10px" }}> <InfoCircleFilled style={{ color: "#3B82F6", fontSize: "18px", fontWeight: "bold" }} /></span><span><b>Grade access</b></span>
                                        <p style={{ marginLeft: "27px" }}>Grades entered will be Final grades in this plan. Student grades will be visible on Student page under grades. Student will see their grades when approved.</p>
                                    </div>
                                </div>
                            </div>
                            {(check_permissions('grade_plan', 'create') || check_permissions('grade_plan', 'update'))&&
                            <div style={{ background: "#FFF9E9", padding: "10px 15px", maxWidth: "450px", border: "1px solid #F5C44B" }}>
                                <Checkbox checked={grade_access} onChange={(e) => e.target.checked ? set_grade_access(1) : set_grade_access(0)}> Include final grades on Transcript Builder</Checkbox>


                            </div>}
                            {(check_permissions('grade_plan', 'create') || check_permissions('grade_plan', 'update'))&&
                            <Button type='primary' onClick={ADD_API} style={{ marginBottom: "20px", marginTop: "50px" }}>Save & Apply</Button>}
                           </>   : <> 
                            <p style={{ padding:"20px", textAlign:"center", color:"red", fontSize:"18px" }}>Please add semester first.</p>
                           </>  }
                        </>
                    )}
                </div>
            </div >
        </>}
          
        </>
    )
}


export default GradePlan