import { Editor } from '@tinymce/tinymce-react';
import { Button, Input, Spin, Tree, notification } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import CourseContainer from '../CourseContainer';
import SubSectionContainer from '../SubSectionContainer';
import { DeleteOutlined, DownOutlined, LoadingOutlined, SmileOutlined, UpOutlined } from '@ant-design/icons';
import SisLoader from '../../../../widgets/loader';
import { ADD_SECTION, ADD_SUBSECTION, DELETE_SECTION, SUBSECTIONS_LIST, UPDATE_SECTION } from '../../../../apis/apis';
import { TINY_KEY } from '../../../../config/config';

const AddSubSection = (props) => {
    const {
        programme_id,
        set_sub_page_loader,
        section_id,
        set_page_loader,
        set_open_section,
        set_show_sub_section,
    } = props;

    const editorRef = useRef(null);
    // const [page_loader2, set_page_loader2] = useState(false)
    const [show_form, set_show_form] = useState(true)

    const [sub_section, set_sub_section] = useState([]);

    const [title, set_title] = useState('');
    const [courses, set_courses] = useState([]);
    const [unit, set_unit] = useState('');
    const [description, set_description] = useState('');
    const [show_courses, set_show_courses] = useState(false);
    const [errors, set_errors] = useState([]);


    // Button loader
    const [button_loader, set_button_loader] = useState(false);

    const ADD_API = async () => {
        set_button_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('title', title);
        FORM_DATA.append('description', editorRef.current.getContent());
        FORM_DATA.append('units', unit);
        FORM_DATA.append('parent', section_id);
        FORM_DATA.append('courses', courses);
        FORM_DATA.append('programme_id', programme_id);
        FORM_DATA.append('course_status', 0);
        const API_RESPONSE = await ADD_SUBSECTION(FORM_DATA);
        if (API_RESPONSE?.data.status) {
            set_show_sub_section(false)
            set_sub_page_loader(true)
            notification.open({
                message: 'Success!!',
                description: 'Programme sub section Successfully added.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            set_open_section(section_id)
            set_page_loader(true)
        } else {
            set_errors(API_RESPONSE?.data.errors);
            set_button_loader(false)
        }

    }
    const delete_section = () => {
        set_show_sub_section(false)
        set_sub_page_loader(true)
    }
    return (
        <>
            <div className='sis-section' style={{ backgroundColor: "#fff" }}>
                <div className='sis-section-head'>
                    <h4> {title ? title : "Add Sub section"}</h4>
                    <div>
                        <b onClick={delete_section}><DeleteOutlined /></b>
                        {show_form ? <b onClick={() => set_show_form(false)}><DownOutlined /> </b> : <b onClick={() => set_show_form(true)}> <UpOutlined /> </b>}
                    </div>
                </div>


                {show_form && <>
                    <div className='sis-section-container'>
                        <div className='input-box'>
                            <label htmlFor="name">Section Title<i style={{ color: "red" }}>*</i></label>
                            <Input value={title} placeholder="Title" id='title' onChange={(e) => set_title(e.target.value)} />
                            {errors?.title && <><span style={{ color: "red" }}>{errors?.title[0]}</span></>}
                        </div>
                        <div className='input-box'>
                            <label htmlFor="name">Section  Description<i style={{ color: "red" }}>*</i></label>
                            <Editor
                                apiKey={TINY_KEY}
                                onInit={(evt, editor) => editorRef.current = editor}
                                initialValue={description}
                                init={{
                                    height: 200,
                                    menubar: false,
                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount imagetools',
                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image code media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                }}
                            />
                            {errors?.description && <><span style={{ color: "red" }}>{errors?.description[0]}</span></>}
                        </div>
                        <div className='input-box'>
                            <label htmlFor="Units">Section Units<i style={{ color: "red" }}>*</i></label>
                            <Input value={unit} maxLength={10} onKeyPress={(event) => { if (!/[0-9]/.test(event.key)) { event.preventDefault(); } }} placeholder="Programme Units" id='Units' onChange={(e) => set_unit(e.target.value)} />
                            {errors?.units && <><span style={{ color: "red" }}>{errors?.units[0]}</span></>}
                        </div>

                        <CourseContainer set_courses={set_courses} courses={courses} />
                        {errors?.courses && <><span style={{ color: "red" }}>{errors?.courses[0]}</span></>}
                        <div className='input-box' style={{ textAlign: "right" }}>
                            {button_loader ? <>
                                <Button type="primary" disabled>Save Sub Section</Button>
                            </> : <Button type="primary" onClick={ADD_API}>Save Sub Section</Button>}
                        </div>

                    </div>
                </>}
            </div>

        </>
    );
};

export default AddSubSection;