import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  DELETE_SEMESTER,
  EDIT_CALENDAR,
  SEMESTERS_LIST,
  STATUS_SEMESTER,
  sisDateFormat,
} from "../../../apis/apis";
import { Button, Popconfirm, Table, notification } from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  SmileOutlined,
  StopOutlined,
} from "@ant-design/icons";
import SisLoader from "../../../widgets/loader";

const SemesterList = (props) => {
  const { calendar_id } = useParams();
  const navigate = useNavigate();
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);

  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if(localStorgeData.role === 2074){
        return true;
    }
    var check_permissions = [];
    if (type === 'create') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
        check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
        return true;
    } else {
        return false;
    }
}

  // Form State
  const [table_list, set_table_list] = useState([]);
  // Form State
  const [title, set_title] = useState("");
  const [description, set_description] = useState("");
  const [calendar_year, set_calendar_year] = useState("");
  const [calendar_start, set_calendar_start] = useState("");
  const [calendar_end, set_calendar_end] = useState("");

  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", calendar_id);
    FORM_DATA.append("token", USER_DATA.token);
    const EDIT_ROLE_API_RESPONSE = await EDIT_CALENDAR(FORM_DATA);
    if (EDIT_ROLE_API_RESPONSE?.data?.status) {
      set_title(EDIT_ROLE_API_RESPONSE?.data?.calendar?.title);
      set_calendar_start(
        EDIT_ROLE_API_RESPONSE?.data?.calendar?.year_start_date
      );
      set_calendar_end(EDIT_ROLE_API_RESPONSE?.data?.calendar?.year_end_date);
      set_description(EDIT_ROLE_API_RESPONSE?.data?.calendar?.description);
    } else {
      set_errors(EDIT_ROLE_API_RESPONSE.data.errors);
    }
  };
  const STATUS_API = async (id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await STATUS_SEMESTER(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Semester status Successfully changed.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/calendar-semester/" + calendar_id);
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };
  const DELETE_API = async (id) => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await DELETE_SEMESTER(FORM_DATA);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: API_RESPONSE?.data?.type ? 'Success!!' : "Failed",
        description: API_RESPONSE.data.message,
        icon: <SmileOutlined style={API_RESPONSE?.data?.type ? { color: 'green' } : { color: 'red' }} />,
    });
      navigate("/calendar-semester/" + calendar_id);
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };
  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Start Date",
      render: (text, record) => {
        return(
          <>
          {sisDateFormat(record.start_date)}
          </>
        )
      }
      
    },
    {
      title: "End Date",
      render: (text, record) => {
        return(
          <>
          {sisDateFormat(record.end_date)}
          </>
        )
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        if (record.status) {
          return <span className="table-status-activate">Active</span>;
        } else {
          return <span className="table-status-deactivate">Inactive</span>;
        }
      },
    },
    {
      title: "Action",
      key: "Action",
      render: (text, record) => {
        return (
          <>
           {check_permissions('academic_semesters', 'status') &&
            <Popconfirm
              title="Change Status"
              description="Are you sure to change Status this semester?"
              onConfirm={() => {
                STATUS_API(btoa(record.id));
              }}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                size="small"
                style={{ backgroundColor: "#888888", marginRight: "5px" }}
              >
                <StopOutlined />
              </Button>
            </Popconfirm>}
            {check_permissions('academic_semesters', 'update') &&
            <Button
              type="primary"
              size="small"
              onClick={() =>
                navigate(
                  "/edit-semester/" + calendar_id + "/" + btoa(record.id)
                )
              }
              style={{ marginRight: "5px" }}
            >
              <EditOutlined />
            </Button>}
            {check_permissions('academic_semesters', 'delete') &&
            <Popconfirm
              title="Change Status"
              description="Are you sure to delete this semester?"
              onConfirm={() => {
                DELETE_API(btoa(record.id));
              }}
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" size="small" danger>
                <DeleteOutlined />
              </Button>
            </Popconfirm>}
          </>
        );
      },
    },
  ];
  // DB list
  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", calendar_id);
    const API_RESPONSE = await SEMESTERS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_table_list(API_RESPONSE.data.semesters);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };
  useEffect(() => {
    LIST_API();
    VIEW_API();
  }, [loader]);

  return (
    <div>
      <div className="theme-content-head">
        <div className="theme-content-left-head">
          <h3>Academic Calendar ({title})</h3>
        </div>
        <div className="theme-content-right-head"></div>
      </div>
      <div className="sis-tab-menu">
        <span onClick={() => navigate("/edit-calendar/" + calendar_id)}>
          Academic Calendar
        </span>
        <span className="active">Semesters</span>
        <span onClick={() => navigate("/calendar-schedule/" + calendar_id)}>
          Schedules
        </span>
      </div>
      <div className="theme-content-head">
        <div className="theme-content-left-head" style={{ marginTop: "15px" }}>
          <h3>Semesters List </h3>
        </div>
        {check_permissions('academic_semesters', 'create') &&
        <div className="theme-content-right-head">
          <Button
            type="primary"
            onClick={() => navigate("/add-semester/" + calendar_id)}
          >
            Add Semester
          </Button>
        </div>}
      </div>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <Table columns={columns} dataSource={table_list} />
        </>
      )}
    </div>
  );
};

export default SemesterList;
