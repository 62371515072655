import axios from 'axios';
export const TINY_KEY = 'wplr2n0gq2sfmmw5kys5hdfa2fzcbf5g923bqaukxtquaw56';
//export const BACKEND_URL = 'http://localhost/lu-sis';
export const BACKEND_URL = 'https://luserv.lincolnuca.edu';
//export const BACKEND_URL = 'https://dev.icore.uk';
//const URL = 'http://localhost/lu-sis/api/';
const URL = 'https://luserv.lincolnuca.edu/api/';
//const URL = 'https://dev.icore.uk/api/';
const Axios = axios.create({
    baseURL: URL,
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json',
       ' Access-Control-Allow-Origin': '*'
    },
});

export default Axios;