import React, { useEffect, useState } from "react";
import "./grading.css";
import { useNavigate, useParams } from "react-router-dom";
import { CheckOutlined, FilterFilled, SearchOutlined, SmileOutlined } from "@ant-design/icons";
import SisLoader from "../../widgets/loader";
import { ACADEMIC_SEMESTER, SEMESTER_WITH_REGISTERED_COURSE, STUDENTS_LISTING_BY_SEMESTER_AND_COURSE, UPDATE_GRADE, sisDateFormat } from "../../apis/apis";
import { Button, Checkbox, Input, Modal, Select, Table, notification } from "antd";
import { render } from "@testing-library/react";
import CommentList from "./CommentList";
// import grading from "./Gradingobject"

function GradingCourses() {
  const navigate = useNavigate();
  const { semester_id, course_id } = useParams();
  const [page_loader, set_page_loader] = useState(false);
  const [semester_details, set_semester_details] = useState([]);
  const [courses_list, set_courses_list] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [students_list, set_students_list] = useState([]);

  const [grade_plan, set_grade_plan] = useState('');
  const [grade_plan_active, set_grade_plan_active] = useState(0);


  const [timing_json, set_timing_json] = useState([]);
  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === 'view') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
    }
    if (type === 'create') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  }


  const STUDENTS_LISTING_BY_SEMESTER_AND_COURSE_list = async () => {
    const FORM_DATA = new FormData();
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append('semester_id', atob(semester_id));
    FORM_DATA.append('course_id', atob(course_id));
    const API_RESPONSE = await STUDENTS_LISTING_BY_SEMESTER_AND_COURSE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_students_list(API_RESPONSE?.data?.students);
      set_grade_plan(API_RESPONSE?.data?.grade_plan);
      set_grade_plan_active(API_RESPONSE?.data?.grade_plan_active);
      setFilteredData(API_RESPONSE?.data?.students);
      if(API_RESPONSE?.data?.course_semester_registration)
        {
          set_semester_details(API_RESPONSE?.data?.course_semester_registration);
          set_timing_json(JSON.parse(API_RESPONSE?.data?.course_semester_registration?.course_semester_registration_timing))
        }
      set_page_loader(false);
    } else {
      set_page_loader(false);
    }
  };


  useEffect(() => {
    STUDENTS_LISTING_BY_SEMESTER_AND_COURSE_list();
  }, [page_loader]);

  const get_timing = () => {
    return (
      <>
        {timing_json.map((item) => (
          <>
            {item?.start_time && (
              <>
                <span
                  style={{
                    width: "140px",
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "14px",
                    margin: "3px 0px"
                  }}
                >
                  {item?.value?.slice(0, 2).toUpperCase()}{" "}
                  {item?.start_time} - {item?.end_time}
                </span>
              </>
            )}
          </>
        ))}
      </>
    );
  };
  const GradeOption = [
    {
      label: "Select grade",
      value: "",
    },
    {
      label: "A",
      value: "A",
    },
    {
      label: "B+",
      value: "B+",
    },
    {
      label: "B",
      value: "B",
    },
    {
      label: "B-",
      value: "B-",
    },
    {
      label: "C+",
      value: "C+",
    },
    {
      label: "C",
      value: "C",
    },
    {
      label: "C-",
      value: "C-",
    },
    {
      label: "D+",
      value: "D+",
    },
    {
      label: "D",
      value: "D",
    },
    {
      label: "CR",
      value: "CR",
    },
    {
      label: "I",
      value: "I",
    },
    {
      label: "IP",
      value: "IP",
    },
    {
      label: "NC",
      value: "NC",
    },
    {
      label: "R",
      value: "R",
    },
    {
      label: "W",
      value: "W",
    },
    {
      label: "Y",
      value: "Y",
    },
  ]

  const UPDATE_GRADE_API = async (grade, student_id, ready_for_review, mark_as_reviewed) => {
    set_page_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("course_id", atob(course_id));
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("ready_for_review", ready_for_review);
    FORM_DATA.append("mark_as_reviewed", mark_as_reviewed);
    FORM_DATA.append("semester_id", atob(semester_id));
    FORM_DATA.append("grade", grade);
    const API_RESPONSE = await UPDATE_GRADE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Garde Successfully updated.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });

    } else {

    }
  }

  const columns = [
    {
      title: "Name",
      className: "minWidthColumn",
      render: (text, record) => {
        return <><span style={{ fontSize: "10px" }}>{record.first_name} {record.middle_name != 'null' && record.middle_name && record.middle_name} {record.last_name}</span></>;
      },
    },
    {
      title: "Grade",
      render: (text, record) => {
        return <>
          <Select style={{ width: "80px" }} value={record?.students_grade == 'null' && !record?.students_grade ? '' : record?.students_grade} onChange={value => UPDATE_GRADE_API(value, record?.id, record?.ready_for_review, record?.mark_as_reviewed)} options={GradeOption} placeholder="Grade" />
          {/* <span style={{ fontSize:"10px" }}>{record?.students_grade}</span> */}
        </>;
      },
    },

    {
      title: "Comments",
      render: (text, record) => {
        return <>
          <CommentList student_id={record?.id} semester_id={semester_details?.course_semester_registration_semester_id} course_id={semester_details?.course_semester_registration_course_id} />
        </>
      }
    },
    {
      title: "Ready for review",
      render: (text, record) => {
        return <>
          <span style={{ cursor: "pointer" }} onClick={() => UPDATE_GRADE_API(record?.students_grade, record?.id, record?.ready_for_review > 0 ? 0 : 1, record?.mark_as_reviewed)}>{record.ready_for_review > 0 ? <>
            <i className="custom-check-box active"><CheckOutlined className="custom-check-box-icon" /></i>
          </> : <><i className="custom-check-box"></i></>} Ready for review</span>
          {/* <span style={{ fontSize:"10px" }}>{record?.students_grade}</span> */}
        </>;
      },
    },
    {
      title: "Mark as reviewed",
      render: (text, record) => {
        return <>
          <span style={{ cursor: "pointer" }} onClick={() => UPDATE_GRADE_API(record?.students_grade, record?.id, record?.ready_for_review, record?.mark_as_reviewed > 0 ? 0 : 1)}>{record.mark_as_reviewed > 0 ? <>
            <i className="custom-check-box active"><CheckOutlined className="custom-check-box-icon" /></i>
          </> : <><i className="custom-check-box"></i></>} Mark as reviewed</span>
          {/* <span style={{ fontSize:"10px" }}>{record?.students_grade}</span> */}
        </>;
      },
    },
  ];
  //  Search
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    var table_data = students_list;
    const filtered = table_data.filter(item =>
      item.first_name.toLowerCase().includes(query.toLowerCase())

    );
    if (query === '') {
      setFilteredData(students_list);
    } else {
      setFilteredData(filtered);
    }
  };
  return (
    <>
      <div>
        <div className="grading-course-head-row">
          <div><Button type="primary" size="small" onClick={() => navigate('/grading/' + semester_id)}>Back</Button></div>
          <div>
            <p>Grading period</p>
            <h3>{semester_details?.academic_semesters_title}</h3>
          </div>
          <div>
            {/* <p>Grading period</p> */}
            <h3>{semester_details?.courses_code}</h3>
            <h3>{semester_details?.courses_title}</h3>
          </div>
          <div>
            {get_timing()}
          </div>
          <div>
            {semester_details?.faculties_name}
          </div>
        </div>

        {page_loader ? (
          <SisLoader />
        ) : (
          <>
            <hr />
            <div className='theme-content-head'>
              <div className='input-box'>
                <Input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleSearch}
                />

              </div>
            </div>
            {grade_plan && <>
              <h4 style={{ marginBottom: "10px" }}>Grading Plan</h4>
              <div className="row grade_plan_row">
                <div className="col-2">
                  <label>Due date</label>
                  <p>{sisDateFormat(grade_plan?.dua_date)}</p>
                </div>
                <div className="col-2">
                  <label>Grading start date</label>
                  <p>{sisDateFormat(grade_plan?.start_grading_date)}</p>
                </div>
                <div className="col-2">
                  <label>Grading end date</label>
                  <p>{sisDateFormat(grade_plan?.end_grading_date)}</p>
                </div>
                <div className="col-2">
                  <label>Review start date</label>
                  <p>{sisDateFormat(grade_plan?.review_start_date)}</p>
                </div>
                <div className="col-2">
                  <label>Review end date</label>
                  <p>{sisDateFormat(grade_plan?.review_end_date)}</p>
                </div>
                <div className="col-2">
                  <label>Status</label>
                  <p>{grade_plan_active > 0 ? <span style={{ color: "green" }}>Active</span> : <span style={{ color: "red" }}>Inactive</span>}</p>
                </div>
              </div> </>}
              { (check_permissions('students_grades','update') || check_permissions('students_grades','create') ) &&
            <div className="sis-tab-content">
              <Table dataSource={filteredData} columns={columns} />
            </div>}
          </>
        )}
      </div>
    </>
  );
}

export default GradingCourses;
