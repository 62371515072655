import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Input, Popconfirm, Select, Spin, Table, notification } from 'antd';
import './style.css';
import type { TableColumnsType, TableProps } from 'antd';
import { CloseOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, SmileOutlined, StopOutlined } from '@ant-design/icons';
import { ADD_Calendar, ADD_USER, CALENDARS_LIST, CATEGORIES_LIST, DELETE_CALENDAR, ROLE_LIST, STATUS_CALENDAR, sisDateFormat } from '../../apis/apis';
import SisLoader from '../../widgets/loader';
import Item from 'antd/es/list/Item';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';

const CalendarList = () => {
    const navigate = useNavigate();
    const [loader, set_loader] = useState(true);
    const [errors, set_errors] = useState([]);
    // Permissions state manage 
    const [all_permissions, set_all_permissions] = useState(false);
    const [permissions, set_permissions] = useState([]);
    const check_permissions = (module_name, type) => {
        const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
        if (localStorgeData.role === 2074) {
            return true;
        }
        var check_permissions = [];

        if (type === 'view') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
        }
        if (type === 'update') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
        }
        if (type === 'status') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
        }
        if (type === 'delete') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
        }

        if (check_permissions.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    // Form State
    const [title, set_title] = useState('');
    const [table_list, set_table_list] = useState([]);
    const STATUS_API = async (id) => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await STATUS_CALENDAR(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Calendar status Successfully changed.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/calendar-list')
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    const DELETE_API = async (id) => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await DELETE_CALENDAR(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: API_RESPONSE?.data?.type ? 'Success!!' : "Failed",
                description: API_RESPONSE.data.message,
                icon: <SmileOutlined style={API_RESPONSE?.data?.type ? { color: 'green' } : { color: 'red' }} />,
            });
            navigate('/calendar-list')
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Start Date',
            render: (text, record) => {
                return(
                  <>
                  {sisDateFormat(record.year_start_date)}
                  </>
                )
              }
           
        },
        {
            title: 'End Date',
            render: (text, record) => {
                return(
                  <>
                  {sisDateFormat(record.year_end_date)}
                  </>
                )
              }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.status) {
                    return (
                        <span className="table-status-activate">Active</span>
                    );
                } else {
                    return (
                        <span className="table-status-deactivate">Inactive</span>
                    );
                }
            }
        },
        {
            title: 'Semesters',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (
                    <>
                        {check_permissions('academic_semesters', 'view') &&   <Button type="primary" size="small" onClick={() => navigate('/calendar-semester/' + btoa(record.id))}>View</Button> }
                    </>
                )
            }
        },
        {
            title: 'Schedule Calendar',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                return (
                    <>
                    {check_permissions('calendars_schedules', 'view') && 
                        <Button type="primary" size="small" onClick={() => navigate('/calendar-schedule/' + btoa(record.id))}>Schedule</Button> }
                    </>
                )
            }
        },
        {
            title: 'Action',
            key: 'Action',
            render: (text, record) => {
                return (
                    <>
                       {check_permissions('academic_calendars', 'status') &&     <Popconfirm
                            title="Change Status"
                            description="Are you sure to change Status this Calendar?"
                            onConfirm={() => {
                                STATUS_API(btoa(record.id))
                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" size="small" style={{ backgroundColor: "#888888", marginRight: "5px" }}><StopOutlined /></Button>
                        </Popconfirm> }

                        {check_permissions('academic_calendars', 'update') &&    <Button type="primary" size="small" onClick={() => navigate('/edit-Calendar/' + btoa(record.id))} style={{ marginRight: "5px" }}><EditOutlined /></Button> }

                        {check_permissions('academic_calendars', 'delete') &&  <Popconfirm
                            title="Change Status"
                            description="Are you sure to delete this Calendar?"
                            onConfirm={() => {
                                DELETE_API(btoa(record.id))
                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button type="primary" size="small" danger><DeleteOutlined /></Button>
                        </Popconfirm>}
                    </>
                )
            }

        },
    ];
    // DB list
    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        const API_RESPONSE = await CALENDARS_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            set_table_list(API_RESPONSE?.data?.calendars);
            set_loader(false);
        } else {
            set_loader(false);
        }
    }
    useEffect(() => {
        LIST_API();
    }, [loader]);
    return (
        <>
            <div>
                {/* <div className='theme-content-head'>
                    <div className='theme-content-left-head'>
                        <h3>Academic Calendar</h3>
                    </div>
                    <div className='theme-content-right-head'>

                    </div>
                </div> */}
                {/* <div className='sis-tab-menu'>
                    <span  className='active'>Academic Calendar</span>
                    <span  onClick={() =>  navigate('/semester-courses/')}>Schedules</span>
                </div> */}
                <div className='sis-tab-content'>
                    <div className='theme-content-head'>
                        <div className='theme-content-left-head'>
                            <h3>Academic Calendar</h3>
                        </div>
                        <div className='theme-content-right-head'>
                        {check_permissions('academic_calendars', 'create') &&
                            <Button type='primary' onClick={() => navigate('/add-calendar')}>Add Calendar</Button> }
                        </div>
                    </div>
                    {loader ? <SisLoader /> : <>
                        <Table columns={columns} dataSource={table_list} />
                    </>}

                </div>
            </div>
        </>
    );
};

export default CalendarList;