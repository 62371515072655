import React, { useState } from 'react';
import { Button, Form, Input, InputNumber, Popconfirm, Table, Typography } from 'antd';
import { useEffect, useRef, useParams } from "react";
import { useNavigate } from 'react-router-dom';

import {

  ADD_PAYMENT_PLAN_API

} from "../../apis/apis";

const originData = [];
for (let i = 0; i < 100; i++) {
  originData.push({
    key: i.toString(),
    name: `Edward ${i}`,
    age: 32,
    address: `London Park no. ${i}`,
  });
}
const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
const Paymentplan = () => {




  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  // const [data, setData] = useState(dataSource);
  const [editingKey, setEditingKey] = useState('');
  const [plan_name, set_plan_name] = useState('');
  const [payment_notes, set_payment_notes] = useState('');
  const [count, setCount] = useState(0);
  const { TextArea } = Input;
  const navigate = useNavigate();




  const ADD_PAYMENT_PLAN = async () => {

    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();

    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append('plan_name', plan_name)
    FORM_DATA.append('notes', payment_notes)
    FORM_DATA.append('no_of_installments', count)


    const API_RESPONSE = await ADD_PAYMENT_PLAN_API(FORM_DATA);

    if (API_RESPONSE?.status) {

      navigate(-1);

    }


  }

  const Cancel_GO_Back = async () => {

    navigate(-1);


  }


  const isEditing = (record) => record.key === editingKey;
  const edit = (record) => {
    form.setFieldsValue({
      paymentName: '',
      paymentDate: '',
      amount: '',
      amountDue: '',
      paid: '',
      ...record,
    });
    setEditingKey(record.key);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const columns = [
    {
      title: 'Payment Name',
      dataIndex: 'paymentName',
      key: 'paymentName',
      editable: true,

    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      editable: true,

    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      editable: true,

    },
    {
      title: 'Amount Due',
      dataIndex: 'amountDue',
      key: 'amountDue',
      editable: true,

    },
    {
      title: 'Paid',
      dataIndex: 'paid',
      key: 'paid',
      editable: true,

    },
    // { /* 
    //   title: 'operation',
    //   dataIndex: 'operation',
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <span>
    //         <Typography.Link
    //           onClick={() => save(record.key)}
    //           style={{
    //             marginRight: 8,
    //           }}
    //         >
    //           Save
    //         </Typography.Link>
    //         <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
    //           <a>Cancel</a>
    //         </Popconfirm>
    //       </span>
    //     ) : (
    //       <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
    //         Edit
    //       </Typography.Link> 
    //     );
    //   },*/
    // },

    {
      title: 'Delete',
      dataIndex: 'operation1',
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.key)}>
            <a>Delete</a>
          </Popconfirm>
        ) : null,
    },



  ];



  const dataTable = [];

  /*   {
       key: '1',
       paymentName: 'Payment 1',
       paymentDate: '2024-01-10',
       amount: '$1818.00',
       amountDue: '$3637.00',
       paid: '$1818.00',
     },
     {
       key: '2',
       paymentName: 'Payment 2',
       paymentDate: '2024-02-10',
       amount: '$1818.00',
       amountDue: '$1819.00',
       paid: '$1818.00',
     },
     {
       key: '3',
       paymentName: 'Payment 3',
       paymentDate: '2024-03-15',
       amount: '$1819.00',
       amountDue: '',
       paid: '',
     },
 
   ]; */
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'amount' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const handleAdd = () => {

    console.log("in handle add");

    setCount(count + 1);


    const pName = 'Payment ' + (count + 1);

    const newData = {

      key: count,
      paymentName: pName,
      paymentDate: '20xx-xx-xx',
      amount: '$ xxxxx.xx',
      amountDue: '$ xxxx.xx',
      paid: '$ xxxxx.xx',


    };

    console.log("in handle add", [...dataSource, newData]);


    setDataSource([...dataSource, newData]);
  }

  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    setCount(count - 1);

  };




  return (

    <>

      <div>

        <span className='Add_attendence_Category'>Create a Payment Plan</span><br></br>
        <br></br>
        <div className='Catagory_Box'>
          <span className='Catagory_Name'>Plan Name  *</span>
          <div className='Absent_Excused' >
            <div className="input-box" style={{ width: "370px", marginTop: "6px" }}>
              <Input value={plan_name} placeholder="plan name" id='plan_name' onChange={(e) => set_plan_name(e.target.value)} />
            </div>

          </div>
        </div>



        <div>
          <p className='Catagory_Name'>Payment Notes</p>

          <TextArea value={payment_notes} showCount maxLength={100} onChange={(e) => set_payment_notes(e.target.value)} placeholder="notes...." />

        </div>


        <div style={{ marginTop: "30px", marginBottom: "5px" }}  >
          <Button type='primary' onClick={handleAdd}> + Payment date</Button>
        </div><br></br>

        <br></br>

        <span className='Reasonss'>Payment dates</span>
        <br></br>








      </div>





      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          bordered
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel,
          }}
        />
      </Form>


      <div style={{ marginTop: "15px" }}>
        <Button type='primary' onClick={ADD_PAYMENT_PLAN}>Save</Button>
        <Button type='primary' style={{ marginLeft: "10px" }} onClick={Cancel_GO_Back} danger>Cancel</Button>
      </div>

    </>




  );
};
export default Paymentplan;