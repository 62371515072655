import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Radio,
  Select,
  Spin,
  Table,
  notification,
} from "antd";
import "./style.css";
import { TableColumnsType, TableProps } from "antd";
import {
  CloseOutlined,
  CloudUploadOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  LoadingOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  ADD_STUDENT,
  ADD_USER,
  PROGRAMS_LIST,
  STUDENTS_API,
  ROLE_LIST,
  SIS_STORAGE,
  NEW_ENROLLMENT,
  UPDATE_ENROLLMENT,
  ENROLLMENTS_UPDATE,
  VIEW_ENROLLMENT,
} from "../../apis/apis";
import SisLoader from "../../widgets/loader";
import Item from "antd/es/list/Item";
import { useNavigate, useParams } from "react-router-dom";
import { CountryDropdown } from "react-country-region-selector";
import dayjs from "dayjs";
import EditRole from "../roles/editRole";

const EditStudent = () => {
  const { id } = useParams();
  const dateFormat = 'MM-DD-YYYY';
  const DATE_CLASS = new Date();
  const current_month =
    DATE_CLASS.getMonth() < 9
      ? "0" + (DATE_CLASS.getMonth() + 1)
      : DATE_CLASS.getMonth();
  const today_date =
    DATE_CLASS.getFullYear() + "-" + current_month + "-" + DATE_CLASS.getDate();
  const [page_loader, set_page_loader] = useState(true);

  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [role_list, set_role_list] = useState([]);
  const studentImage = useRef(null);
  const studentEnrollImage = useRef(null);
  const studentHighSchoolDiplomaImage = useRef(null);
  const studentTranscriptsHigherEducationalImage = useRef(null);
  const studentResumeImage = useRef(null);
  const studentPasspartImage = useRef(null);
  const studentProofEnglishImage = useRef(null);
  const studentEssayExplainingImage = useRef(null);

  // Form State
  const [application_start_on, set_application_start_on] = useState(today_date);
  const [application_no, set_application_no] = useState("");

  // basic information
  const [image, set_image] = useState("");
  const [first_name, set_first_name] = useState("");
  const [middle_name, set_middle_name] = useState("");
  const [last_name, set_last_name] = useState("");

  const [student_id, set_student_id] = useState("");
  const [gender, set_gender] = useState("");
  const [student_athlete, set_student_athlete] = useState("");
  const [birth_date, set_birth_date] = useState("");
  const [country_of_birth, set_country_of_birth] = useState("");
  const [city_of_birth, set_city_of_birth] = useState("");
  const [student_category, set_student_category] = useState("");
  const [ssn, set_ssn] = useState("");
  const [ssnerror, set_Ssn_Error] = useState('');
  const [programme_list, set_programme_list] = useState([]);
  const [student_status_check, set_student_status_check] = useState();
  // Contact
  const [phoneerror, set_Phone_Error] = useState('');
  const [emergencyphoneerror, set_emergency_phone_error] = useState('');
  const [phone, set_phone] = useState("");
  const [email, set_email] = useState("");
  const [secondary_email, set_secondary_email] = useState("");
  const [citizenship, set_citizenship] = useState("");
  const [emergency_first_name, set_emergency_first_name] = useState("");
  const [emergency_last_name, set_emergency_last_name] = useState("");
  const [emergency_country_code, set_emergency_country_code] = useState("");
  const [emergency_phone, set_emergency_phone] = useState("");
  const [emergency_email, set_emergency_email] = useState("");
  const [emergency_relationship, set_emergency_relationship] = useState("");
  const [emergency_address1, set_emergency_address1] = useState("");
  const [emergency_address2, set_emergency_address2] = useState("");
  const [emergency_address_city, set_emergency_address_city] = useState("");
  const [emergency_address_state, set_emergency_address_state] = useState("");
  const [emergency_address_postal, set_emergency_address_postal] = useState("");
  const [emergency_address_country, set_emergency_address_country] = useState("");
  const [mailing_address1, set_mailing_address1] = useState("");
  const [mailing_address2, set_mailing_address2] = useState("");
  const [mailing_address_city, set_mailing_address_city] = useState("");
  const [mailing_address_state, set_mailing_address_state] = useState("");
  const [mailing_address_postal, set_mailing_address_postal] = useState("");
  const [mailing_address_country, set_mailing_address_country] = useState("");
  const [home_country_address1, set_home_country_address1] = useState("");
  const [home_country_address2, set_home_country_address2] = useState("");
  const [home_country_address_city, set_home_country_address_city] =
    useState("");
  const [home_country_address_state, set_home_country_address_state] =
    useState("");
  const [home_country_address_postal, set_home_country_address_postal] =
    useState("");
  const [home_country_address_country, set_home_country_address_country] =
    useState("");

  const [social_security_number, set_social_security_number] = useState("");
  const [programme_intended, set_programme_intended] = useState("");
  const [school_decision, set_school_decision] = useState("");
  const [application_checklist, set_application_checklist] = useState("");
  const [financial_aid, set_financial_aid] = useState("");

  // Document
  const [enrollments_agreements, set_enrollments_agreements] = useState("");
  const [
    high_school_diploma_and_transcripts,
    set_high_school_diploma_and_transcripts,
  ] = useState("");
  const [
    transcripts_from_higher_educational,
    set_transcripts_from_higher_educational,
  ] = useState("");
  const [resume, set_resume] = useState("");
  const [
    photocopy_of_passport_or_equivalent_documents,
    set_photocopy_of_passport_or_equivalent_documents,
  ] = useState("");
  const [proof_of_english, set_proof_of_english] = useState("");
  const [essay_explaining_interest, set_essay_explaining_interest] =
    useState("");

  const [api_image, set_api_image] = useState("");
  const [api_enrollments_agreements, set_api_enrollments_agreements] =
    useState("");
  const [
    api_high_school_diploma_and_transcripts,
    set_api_high_school_diploma_and_transcripts,
  ] = useState("");
  const [
    api_transcripts_from_higher_educational,
    set_api_transcripts_from_higher_educational,
  ] = useState("");
  const [api_resume, set_api_resume] = useState("");
  const [api_proof_of_english, set_api_proof_of_english] = useState("");
  const [
    api_photocopy_of_passport_or_equivalent_documents,
    set_api_photocopy_of_passport_or_equivalent_documents,
  ] = useState("");
  const [api_essay_explaining_interest, set_api_essay_explaining_interest] =
    useState("");

  // Registration Status
  const [student_status, set_student_status] = useState("");

  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", id);
    FORM_DATA.append("token", USER_DATA.token);
    const EDIT_ROLE_API_RESPONSE = await VIEW_ENROLLMENT(FORM_DATA);
    console.log(EDIT_ROLE_API_RESPONSE);
    if (EDIT_ROLE_API_RESPONSE?.data?.status) {
      set_application_no(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.application_no
      );
      set_first_name(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.first_name);
      set_middle_name(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.middle_name);
      set_last_name(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.last_name);
      set_student_id(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.student_id);
      set_birth_date(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.birth_date);
      set_country_of_birth(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.country_of_birth
      );
      set_city_of_birth(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.city_of_birth
      );
      set_gender(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.gender);
      set_student_athlete(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.student_athlete);
      set_api_image(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.image);
      set_citizenship(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.citizenship);
      set_ssn(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.ssn);
      set_student_category(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.student_category
      );
      set_email(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.primary_email);
      set_secondary_email(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.secondary_email ? EDIT_ROLE_API_RESPONSE?.data?.enrollments?.secondary_email : ''
      );
      set_phone(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.phone);
      set_emergency_first_name(EDIT_ROLE_API_RESPONSE?.data?.emergency_contacts?.first_name);
      set_emergency_last_name(EDIT_ROLE_API_RESPONSE?.data?.emergency_contacts?.last_name);
      set_emergency_email(EDIT_ROLE_API_RESPONSE?.data?.emergency_contacts?.email);
      set_emergency_relationship(EDIT_ROLE_API_RESPONSE?.data?.emergency_contacts?.relationship);
      set_emergency_phone(EDIT_ROLE_API_RESPONSE?.data?.emergency_contacts?.phone);
      set_emergency_address_country(EDIT_ROLE_API_RESPONSE?.data?.emergency_contacts?.phone);
      var emergency_address = JSON.parse(EDIT_ROLE_API_RESPONSE?.data?.emergency_contacts?.address);
      set_emergency_address_country(emergency_address?.emergency_address_country);
      set_emergency_address_city(emergency_address?.emergency_address_city);
      set_emergency_address_state(emergency_address?.emergency_address_state);
      set_emergency_address_postal(emergency_address?.emergency_address_postal);
      set_emergency_address1(emergency_address?.emergency_address1);
      set_emergency_address2(emergency_address?.emergency_address2);
      var mailing_address = JSON.parse(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.mailing_address
      );
      set_mailing_address_city(mailing_address.mailing_address_city);
      set_mailing_address_state(mailing_address.mailing_address_state);
      set_mailing_address_postal(mailing_address.mailing_address_postal);
      set_mailing_address_country(mailing_address.mailing_address_country);
      set_mailing_address1(mailing_address.mailing_address1);
      set_mailing_address2(mailing_address.mailing_address2);

      var home_country_address = JSON.parse(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.home_country_address
      );

      set_home_country_address_city(
        home_country_address.home_country_address_city
      );
      set_home_country_address_state(
        home_country_address.home_country_address_state
      );
      set_home_country_address_postal(
        home_country_address.home_country_address_postal
      );
      set_home_country_address_country(
        home_country_address.home_country_address_country
      );
      set_home_country_address1(home_country_address.home_country_address1);
      set_home_country_address2(home_country_address.home_country_address2);
      set_programme_intended(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.programme_intended
      );
      set_school_decision(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.school_decision
      );
      set_application_checklist(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.application_checklist
      );
      set_financial_aid(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.financial_aid
      );
      set_student_status(
        EDIT_ROLE_API_RESPONSE?.data?.enrollments?.student_status
      );
      set_student_status_check(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.student_status)

      set_api_enrollments_agreements(
        EDIT_ROLE_API_RESPONSE?.data?.documents?.enrollments_agreements
      );
      set_api_high_school_diploma_and_transcripts(
        EDIT_ROLE_API_RESPONSE?.data?.documents
          ?.high_school_diploma_and_transcripts
      );
      set_api_transcripts_from_higher_educational(
        EDIT_ROLE_API_RESPONSE?.data?.documents
          ?.transcripts_from_higher_educational
      );
      set_api_resume(EDIT_ROLE_API_RESPONSE?.data?.documents?.resume);
      set_api_photocopy_of_passport_or_equivalent_documents(
        EDIT_ROLE_API_RESPONSE?.data?.documents
          ?.photocopy_of_passport_or_equivalent_documents
      );
      set_api_proof_of_english(
        EDIT_ROLE_API_RESPONSE?.data?.documents?.proof_of_english
      );
      set_api_essay_explaining_interest(
        EDIT_ROLE_API_RESPONSE?.data?.documents?.essay_explaining_interest
      );

      set_page_loader(false);
    } else {
      set_errors(EDIT_ROLE_API_RESPONSE?.data?.errors);
    }
  };
  const PROGRAMS_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
    if (API_RESPONSE.data.status) {
      set_programme_list(API_RESPONSE.data.programmes);
    } else {
      set_loader(false);
    }
  };
  useEffect(() => {
    PROGRAMS_LIST_API();
    VIEW_API();
    // STUDENTS_API();
  }, []);

  // handle Image Change

  const handleImageChange = (e, type) => {
    const files = e.target.files;
    const maxSize = 33 * 1024 * 1024;
    if (
      (files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png" ||
        files[0].type === "application/pdf") &&
      files[0].size < maxSize
    ) {
      if (type == "enrollments_agreements") {
        set_enrollments_agreements(files[0]);
        // set_api_enrollments_agreements(files[0]);
      }
      if (type === "high_school_diploma_and_transcripts") {
        set_high_school_diploma_and_transcripts(files[0]);
        // set_api_high_school_diploma_and_transcripts(files[0]);
      }
      if (type === "resume") {
        set_resume(files[0]);
        // set_api_resume(files[0]);
      }
      if (type === "image") {
        set_image(files[0]);
        // set_api_image(files[0]);
      }
      if (type === "photocopy_of_passport_or_equivalent_documents") {
        set_photocopy_of_passport_or_equivalent_documents(files[0]);
        // set_api_photocopy_of_passport_or_equivalent_documents(files[0]);
      }
      if (type === "proof_of_english") {
        set_proof_of_english(files[0]);
        // set_api_proof_of_english(files[0]);
      }
      if (type === "transcripts_from_higher_educational") {
        set_transcripts_from_higher_educational(files[0]);
        // set_api_transcripts_from_higher_educational(files[0]);
      }
      if (type === "essay_explaining_interest") {
        set_essay_explaining_interest(files[0]);
        // set_api_essay_explaining_interest(files[0]);
      }
    } else {
      notification.open({
        message: "Not Allowed",
        description:
          "Please check file format must be.jpg or .png and Max. file size 33.65 Mb",
        icon: <SmileOutlined style={{ color: "red" }} />,
      });
    }
  };

  // handleRemove Image
  const handleRemoveImage = (e, type) => {
    if (type == "enrollments_agreements") {
      set_enrollments_agreements("");
      studentEnrollImage.current.value='';

    }
    if (type === "high_school_diploma_and_transcripts") {
      set_high_school_diploma_and_transcripts("");
      studentHighSchoolDiplomaImage.current.value=''
    }
    if (type === "resume") {
      set_resume("");
      studentResumeImage.current.value='';
    }
    if (type === "image") {
      set_image("");
      studentImage.current.value='';
    }
    if (type === "photocopy_of_passport_or_equivalent_documents") {
      set_photocopy_of_passport_or_equivalent_documents("");
      studentPasspartImage.current.value='';
    }
    if (type === "transcripts_from_higher_educational") {
      set_transcripts_from_higher_educational("");
      studentTranscriptsHigherEducationalImage.current.value='';
    }
    if (type === "proof_of_english") {
      set_proof_of_english("");
      studentProofEnglishImage.current.value='';
    }
    if (type === "essay_explaining_interest") {
      set_essay_explaining_interest("");
      studentEssayExplainingImage.current.value='';
    }
  };

  const UPDATE_API = async () => {
    if(!phoneerror && !emergencyphoneerror && !ssnerror)
      {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("application_start_on", application_start_on);

    FORM_DATA.append("application_no", application_no);
    FORM_DATA.append("id", id);
    //Basic Information
    FORM_DATA.append("image", image);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("first_name", first_name);
    FORM_DATA.append("middle_name", middle_name);
    FORM_DATA.append("last_name", last_name);
    FORM_DATA.append("birth_date", birth_date);
    FORM_DATA.append("country_of_birth", country_of_birth);
    FORM_DATA.append("city_of_birth", city_of_birth);
    FORM_DATA.append("gender", gender);
    FORM_DATA.append("citizenship", citizenship);
    FORM_DATA.append("ssn", ssn);
    FORM_DATA.append("student_category", student_category);
    FORM_DATA.append("student_athlete", student_athlete);

    // Contacts
    FORM_DATA.append("email", email);
    FORM_DATA.append("secondary_email", secondary_email);
    FORM_DATA.append("phone", phone);


    // Emergency Contact
    FORM_DATA.append("emergency_first_name", emergency_first_name);
    FORM_DATA.append("emergency_last_name", emergency_last_name);
    FORM_DATA.append("emergency_email", emergency_email);
    FORM_DATA.append("emergency_relationship", emergency_relationship);
    FORM_DATA.append("emergency_phone", emergency_phone);

    // Emergency Address
    FORM_DATA.append("emergency_address_country", emergency_address_country);
    FORM_DATA.append("emergency_address_city", emergency_address_city);
    FORM_DATA.append("emergency_address_state", emergency_address_state);
    FORM_DATA.append("emergency_address_postal", emergency_address_postal);
    FORM_DATA.append("emergency_address1", emergency_address1);
    FORM_DATA.append("emergency_address2", emergency_address2);

    // Mailing Address
    FORM_DATA.append("mailing_address_country", mailing_address_country);
    FORM_DATA.append("mailing_address_city", mailing_address_city);
    FORM_DATA.append("mailing_address_state", mailing_address_state);
    FORM_DATA.append("mailing_address_postal", mailing_address_postal);
    FORM_DATA.append("mailing_address1", mailing_address1);
    FORM_DATA.append("mailing_address2", mailing_address2);

    // Home Address
    FORM_DATA.append(
      "home_country_address_country",
      home_country_address_country
    );
    FORM_DATA.append("home_country_address_city", home_country_address_city);
    FORM_DATA.append("home_country_address_state", home_country_address_state);
    FORM_DATA.append(
      "home_country_address_postal",
      home_country_address_postal
    );
    FORM_DATA.append("home_country_address1", home_country_address1);
    FORM_DATA.append("home_country_address2", home_country_address2);

    FORM_DATA.append("programme_intended", programme_intended);
    FORM_DATA.append("school_decision", school_decision);
    FORM_DATA.append("application_checklist", application_checklist);
    FORM_DATA.append("financial_aid", financial_aid);

    // Document
    FORM_DATA.append("enrollments_agreements", enrollments_agreements);
    FORM_DATA.append(
      "high_school_diploma_and_transcripts",
      high_school_diploma_and_transcripts
    );
    FORM_DATA.append(
      "transcripts_from_higher_educational",
      transcripts_from_higher_educational
    );
    FORM_DATA.append("resume", resume);
    FORM_DATA.append(
      "photocopy_of_passport_or_equivalent_documents",
      photocopy_of_passport_or_equivalent_documents
    );
    FORM_DATA.append("proof_of_english", proof_of_english);
    FORM_DATA.append("essay_explaining_interest", essay_explaining_interest);

    FORM_DATA.append("student_status", student_status);

    const API_RESPONSE = await ENROLLMENTS_UPDATE(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Student Basic Information Successfully added.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/registration-list/");
    } else {
      set_errors(API_RESPONSE?.data?.errors);
      set_loader(false);
    }
  }
  };
  // const checkPDF = (filename) => {
  //   var ext = fileName.split('.').reverse()[0]
  //   if(ext === 'pdf') {
  //     return true;
  //   }
  //   return false;
  // }
  return (
    <div>
      <div className="theme-content-head">
        <div className="theme-content-left-head">
          <h3>Edit Enrollment </h3>
        </div>
        <div className="theme-content-right-head"></div>
      </div>
      {page_loader ? (
        <SisLoader />
      ) : (
        <>
          <div className="common-form">
            {errors?.try && (
              <>
                <span style={{ color: "red" }}>{errors?.try[0]}</span>
              </>
            )}
            {errors?.catch && (
              <>
                <span style={{ color: "red" }}>{errors?.catch[0]}</span>
              </>
            )}

            {/* Student Application Number */}
            <div className="row">
              <div className="col-6">
                <div className="input-box">
                  <label>
                    Application Number <i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    value={application_no}
                    placeholder="Application Number"
                    onChange={(e) => set_application_no(e.target.value)}
                  />
                  {errors?.application_no && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.application_no[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div>
              <br />
              <hr />
              <br />
              <div className="theme-content-left-head">
                <h3>Basic Information </h3>
              </div>
              <br />
            </div>

            <div className="row">
              {/* Student Image */}
              <div className="col-3">
                <div className="input-box" style={{ position: "relative" }}>
                  <label>Image</label>
                  <br></br>
                  <label className="upload-box upload-box-2">
                    <input
                      ref={studentImage}
                      type="file"
                      id="upload-images"
                      accept="image/*"
                      style={{ height: "0px", overflow: "hidden" }}
                      onChange={(e) => handleImageChange(e, "image")}
                    />
                    <CloudUploadOutlined />
                    Upload
                  </label>

                  {image ? (
                    <>
                      <div className="image-box">
                        <img
                          src={URL.createObjectURL(image)}
                          frameborder="0"
                          style={{ width: "100%", height: "100%" }}
                        ></img>
                      </div>
                      <button
                        className="image-remove"
                        onClick={(e) => handleRemoveImage(e, "image")}
                      >
                        <CloseOutlined />
                      </button>
                    </>
                  ) : (
                    <>
                      {api_image && (
                        <>
                          <div className="image-box">
                            <img
                              src={SIS_STORAGE + "/enrollments/" + api_image}
                              frameborder="0"
                              style={{ width: "100%", height: "150px" }}
                            ></img>
                          </div>

                        </>
                      )}
                    </>
                  )}
                  {errors?.image && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.image[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* First Name */}
              <div className="col-3">
                <div className="input-box">
                  <label>
                    First Name<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    value={first_name}
                    placeholder="First Name"
                    onChange={(e) => set_first_name(e.target.value)}
                  />
                  {errors?.first_name && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.first_name[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* Middle Name */}
              <div className="col-3">
                <div className="input-box">
                  <label>Middle Name (Optional)</label>
                  <Input
                    value={middle_name === 'null' ? '' : middle_name}
                    placeholder="Middle Name"
                    onChange={(e) => set_middle_name(e.target.value)}
                  />
                </div>
              </div>

              {/* Last Name */}
              <div className="col-3">
                <div className="input-box">
                  <label>
                    Last Name<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    value={last_name}
                    placeholder="Last Name"
                    onChange={(e) => set_last_name(e.target.value)}
                  />
                  {errors?.last_name && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.last_name[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              {/* Student ID */}
              <div className="col-3">
                <div className="input-box">
                  <label>
                    Student ID<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    value={student_id}
                    placeholder="Student ID"
                    onChange={(e) => set_student_id(e.target.value)}
                  />
                  {errors?.student_id && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.student_id[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* Birth of Date */}
              <div className="col-3">
                <div className="input-box">
                  <label style={{ textTransform: "none" }}>
                    Date of Birth<i style={{ color: "red" }}>*</i>
                  </label>
                  <DatePicker format={dateFormat}
                    style={{ width: "100%", height: "40px" }}
                    value={birth_date && dayjs(birth_date)}
                    onChange={(date, dateString) =>
                      set_birth_date(dateString)
                    }
                  />
                  {errors?.birth_date && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.birth_date[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* Country Of Birth */}
              <div className="col-3">
                <div className="input-box">
                  <label style={{ textTransform: "none" }}>
                    Country of Birth<i style={{ color: "red" }}>*</i>
                  </label>
                  <CountryDropdown
                    style={{ width: "100%", height: "40px" }}
                    key={"country_of_birth"}
                    value={country_of_birth}
                    onChange={(value) => set_country_of_birth(value)}
                    className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                  />
                  {errors?.country_of_birth && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.country_of_birth[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* Birth Place / City */}
              <div className="col-3">
                <div className="input-box">
                  <label>
                    Birth Place<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    placeholder="Enter Your Birth City"
                    value={city_of_birth}
                    onChange={(e) => set_city_of_birth(e.target.value)}
                  />
                  {errors?.city_of_birth && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.city_of_birth[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* Gender */}
            <div className="row">
              <div className="col-3">
                <div className="input-box">
                  <label>
                    Gender<i style={{ color: "red" }}>*</i>
                  </label>
                  <br></br>
                  <Select
                    value={gender}
                    style={{ width: "100%", height: "40px" }}
                    onChange={(value) => set_gender(value)}
                    placeholder="--Select Gender--"
                  >
                    <Select.Option value="male">Male</Select.Option>
                    <Select.Option value="female">Female</Select.Option>
                  </Select>
                  {errors?.gender && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.gender[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* Citizenship */}

              <div className="col-3">
                <div className="input-box">
                  <label>
                    Citizenship<i style={{ color: "red" }}>*</i>
                  </label>
                  <CountryDropdown
                    style={{ width: "100%", height: "40px" }}
                    key={"citizenship"}
                    onChange={(value) => set_citizenship(value)}
                    value={citizenship}
                    className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                  />
                  {errors?.citizenship && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.citizenship[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
              {/* Student Category */}
              <div className="col-3">
                <div className="input-box">
                  <label>
                    Student Category<i style={{ color: "red" }}>*</i>
                  </label>
                  <br />
                  <Select
                    value={student_category}
                    style={{ width: "100%", height: "40px" }}
                    placeholder="--Select Student Category--"
                    onChange={(value) => set_student_category(value)}
                  >
                    <Select.Option value="International">
                      International
                    </Select.Option>
                    <Select.Option value="Domestic">Domestic</Select.Option>
                  </Select>
                  {errors?.student_category && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.student_category[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
              {/* SSN(Optional) */}
              <div className="col-3">
                <div className="input-box">
                  <label>SSN (Optional)</label>
                  <Input
                   value={ssn === "null" ? "" : ssn}
                  maxLength={11}
                    placeholder="SSN"
                   // onChange={(e) => set_ssn(e.target.value)}
                    keyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const ssnNumber = e.target.value;
                      // Remove non-digit characters from the input
                      const cleanedSsnNumber = ssnNumber.replace(/[^0-9]/g, '');
                      if (cleanedSsnNumber.length <= 9)
                        {
                          set_Ssn_Error('')
                           // Format the phone number as 510-62-8022
                      let formattedSsnNumber = '';
                      for (let i = 0; i < cleanedSsnNumber.length; i++) {
                        if (i === 3 || i === 5) {
                          formattedSsnNumber += '-';
                        }
                        formattedSsnNumber += cleanedSsnNumber[i];
                      }
                      set_ssn(formattedSsnNumber);
                          set_Ssn_Error('')
                        }
                        else
                        {
                          set_ssn(cleanedSsnNumber)
                          set_Ssn_Error("SSN Number Maximum Length Should be 9 Digit ")
                        }
                    }}
                  />
                  {errors?.ssn && (<><span style={{ color: "red" }}>{errors?.ssn[0]}</span></>)
                   || ssnerror && (<><span style={{ color: "red" }}>{ssnerror}</span></>)
                  }
                </div>
              </div>
            </div>
            <div className="row">
                {/* Student Athlete */}
                <div className="col-3">
                <div className="input-box">
                  <label>
                  Student Athlete<i style={{ color: "red" }}>*</i>
                  </label>
                  <br></br>
                  <Select
                  value={student_athlete}
                    style={{ width: "100%", height: "40px" }}
                    onChange={(value) => set_student_athlete(value)}
                    placeholder="--Select Student Athlete--"
                  >
                    <Select.Option value={1}>Yes</Select.Option>
                    <Select.Option value={0}>No</Select.Option>
                  </Select>
                  {errors?.student_athlete && (
                    <>
                      <span style={{ color: "red" }}>{errors?.student_athlete[0]}</span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
            <div className="theme-content-left-head">
              <h3>Contact Details </h3>
            </div>
            <br />
            <div className="row">
              {/* Primary Email */}
              <div className="col-4">
                <div className="input-box">
                  <label htmlFor="email">
                    Primary Email<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    id="email"
                    value={email}
                    placeholder="example123@gmail.com"
                    onChange={(e) => set_email(e.target.value)}
                  />
                  {errors?.email && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.email[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* Secondary Email */}
              <div className="col-4">
                <div className="input-box">
                  <label htmlFor="secoundary_email">Secondary Email</label>
                  <Input
                    value={secondary_email}
                    id="secondary_email"
                    placeholder="secondaryemail123@gmail.com"
                    onChange={(e) => set_secondary_email(e.target.value)}
                  />
                   {errors?.secondary_email && (
                    <>
                      <span style={{ color: "red" }}>{errors?.secondary_email[0]}</span>
                    </>
                  )}
                </div>
              </div>

              {/* Phone Number */}
              <div className="col-4">
                <div className="input-box">
                  <label htmlFor="phone">
                    Phone Number<i style={{ color: "red" }}>*</i>
                  </label>
                  <Input
                    value={phone}
                    maxLength={12}
                    placeholder="Phone Number"
                    keyPress={(event) => {
                      if (!/[0-9]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    id="phone"
                    // onChange={(e) => set_phone(e.target.value)}
                    onChange={(e) => {
                      const userphoneNumber = e.target.value;
                      // Remove non-digit characters from the input
                      const usercleanedPhoneNumber = userphoneNumber.replace(/[^0-9]/g, '');
                      if (usercleanedPhoneNumber.length <= 10)
                        {
                          set_Phone_Error('')
                           // Format the phone number as 510-628-8022
                      let userformattedPhoneNumber = '';
                      for (let i = 0; i < usercleanedPhoneNumber.length; i++) {
                        if (i === 3 || i === 6) {
                          userformattedPhoneNumber += '-';
                        }
                        userformattedPhoneNumber += usercleanedPhoneNumber[i];
                      }
                    
                      set_phone(userformattedPhoneNumber);
                      set_Phone_Error('')

                        }
                        else
                        {
                          set_phone(usercleanedPhoneNumber)
                          set_Phone_Error("Phone Number Maximum Length Should be 10 Digit ")
                        }
                     
                    }}
                  />
                  {
                  errors?.phone && (<><span style={{ color: "red" }}>{errors?.phone[0]}</span></>)
                  || phoneerror && <><span style={{ color: "red" }}>{phoneerror}</span></>
                  }
                </div>
              </div>
            </div>

            {/* Mailing Address */}
            <div style={{ marginTop:"20px" }}>
              <label className="cus-label">
                Mailing Address<i style={{ color: "red" }}>*</i>
              </label>
              <div className="row">
                {/* country */}
                <div className="col-3">
                  <div className="input-box">
                    <label htmlFor="country">
                      Country<i style={{ color: "red" }}>*</i>
                    </label>
                    <CountryDropdown
                      style={{ width: "100%", height: "40px" }}
                      placeholde="--Select Country--"
                      key={"mailing_address_country"}
                      value={mailing_address_country}
                      onChange={(value) =>
                        set_mailing_address_country(value)
                      }
                      className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                    />
                    {errors?.mailing_address_country && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.mailing_address_country[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                {/* city */}
                <div className="col-3">
                  <div className="input-box">
                    <label htmlFor="email">
                      City<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      value={mailing_address_city}
                      placeholder="City"
                      onChange={(e) =>
                        set_mailing_address_city(e.target.value)
                      }
                    />
                    {errors?.mailing_address_city && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.mailing_address_city[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                {/* state */}
                <div className="col-3">
                  <div className="input-box">
                    <label htmlFor="email">
                      State<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      placeholder="State"
                      value={mailing_address_state}
                      onChange={(e) => set_mailing_address_state(e.target.value)}
                    />
                    {errors?.mailing_address_state && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.mailing_address_state[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                {/* Postal Code */}
                <div className="col-3">
                  <div className="input-box">
                    <label htmlFor="email">
                      Zip / Postal Code<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      value={mailing_address_postal}
                      placeholder="Zip/Postal"
                      onChange={(e) =>
                        set_mailing_address_postal(e.target.value)
                      }
                      keyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors?.mailing_address_postal && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.mailing_address_postal[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* Mailing Address1 */}
              <div className="row">
                <div className="col-6" >
                  <div className="input-box">
                    <label>
                      Address Field 1<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      placeholder="Address"
                      value={mailing_address1}
                      onChange={(e) => set_mailing_address1(e.target.value)}
                    />
                    {errors?.mailing_address1 && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.mailing_address1[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                {/* Mailing Address2 */}
                <div className="col-6" >
                  <div className="input-box">
                    <label>
                      Address Field 2
                    </label>
                    <Input
                      placeholder="Address"
                      value={mailing_address2 === 'null' ? '' : mailing_address2}
                      onChange={(e) => set_mailing_address2(e.target.value)}
                    />
                    {errors?.mailing_address2 && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.mailing_address2[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* Home Address */}
            <div style={{ marginTop:"20px" }}>
              <label className="cus-label">
                Home Address<i style={{ color: "red" }}>*</i>
              </label>
              <div className="row">
                {/* Country */}
                <div className="col-3">
                  <div className="input-box">
                    <label>
                      Country<i style={{ color: "red" }}>*</i>
                    </label>
                    <CountryDropdown
                      style={{ width: "100%", height: "40px" }}
                      value={home_country_address_country}
                      key={"home_country_address_country"}
                      onChange={(value) =>
                        set_home_country_address_country(value)
                      }
                      className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined"
                    />
                    {errors?.home_country_address_country && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.home_country_address_country[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                {/* City */}
                <div className="col-3">
                  <div className="input-box">
                    <label>
                      City<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      value={home_country_address_city}
                      placeholder="City"
                      onChange={(e) =>
                        set_home_country_address_city(e.target.value)
                      }
                    />
                    {errors?.home_country_address_city && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.home_country_address_city[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                {/* State */}
                <div className="col-3">
                  <div className="input-box">
                    <label>
                      State<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      placeholder="State"
                      value={home_country_address_state}
                      onChange={(e) =>
                        set_home_country_address_state(e.target.value)
                      }
                    />
                    {errors?.home_country_address_state && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.home_country_address_state[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                {/* Postal / ZIP Code */}
                <div className="col-3">
                  <div className="input-box">
                    <label>
                      ZIP / Postal Code <i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      value={home_country_address_postal}
                      placeholder="Zip/Postal"
                      onChange={(e) =>
                        set_home_country_address_postal(e.target.value)
                      }
                      keyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors?.home_country_address_postal && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.home_country_address_postal[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                {/*  Home Address1 */}
                <div className="col-6">
                  <div className="input-box">
                    <label>
                      Address Field 1<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      placeholder="Address"
                      value={home_country_address1}
                      onChange={(e) => set_home_country_address1(e.target.value)}
                    />
                    {errors?.home_country_address1 && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.home_country_address1[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                {/*  Home Address2 */}
                <div className="col-6">
                  <div className="input-box">
                    <label>
                      Address Field 2
                    </label>
                    <Input
                      placeholder="Address"
                      value={home_country_address2 === 'null' ? '' : home_country_address2}
                      onChange={(e) => set_home_country_address2(e.target.value)}
                    />
                    {errors?.home_country_address2 && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.home_country_address2[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <hr />
            <br />
            <div className="theme-content-left-head">
              <h3>Emergency Contact Details </h3>
            </div>
            <br />
            {/* {Emergency Contact} */}
            <div>
              <label className="cus-label">
                Emergency Contact<i style={{ color: "red" }}>*</i>
              </label>
              <div className="row">

                {/* Emergency First Name */}
                <div className="col-3">
                  <div className="input-box">
                    <label>
                      First Name<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      placeholder="First Name"
                      value={emergency_first_name === 'null' ? ' ' : emergency_first_name}
                      onChange={(e) => set_emergency_first_name(e.target.value)}
                    />
                    {errors?.emergency_first_name && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.emergency_first_name[0]}
                      </span>
                    </>
                  )}
                  </div>
                </div>
                {/* Emergency Last Name */}
                <div className="col-3">
                  <div className="input-box">
                    <label>
                      Last Name<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      placeholder="Last Name"
                      value={emergency_last_name === 'null' ? '' : emergency_last_name}
                      onChange={(e) => set_emergency_last_name(e.target.value)}
                    />
                    {errors?.emergency_last_name && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.emergency_last_name[0]}
                      </span>
                    </>
                  )}
                  </div>
                </div>

                {/* Emergency Email */}
                <div className="col-3">
                  <div className="input-box">
                    <label htmlFor="email">
                      Email<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      id="email"
                      value={emergency_email === 'null' ? '' : emergency_email}
                      placeholder="example123@gmail.com"
                      onChange={(e) => set_emergency_email(e.target.value)}
                    />
                    {errors?.emergency_email && (
                    <>
                      <span style={{ color: "red" }}>{errors?.emergency_email[0]}</span>
                    </>
                  )}
                  </div>
                </div>
                {/* Emergency Phone Number */}
                <div className="col-3">
                  <div className="input-box">
                    <label htmlFor="phone">
                      Phone Number<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      maxLength={12}
                      value={emergency_phone === 'null' ? '' : emergency_phone}
                      placeholder="Phone Number"
                      keyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      id="phone"
                      // onChange={(e) => set_emergency_phone(e.target.value)}
                      onChange={(e) => {
                        const phoneNumber = e.target.value;
                        // Remove non-digit characters from the input
                        const cleanedPhoneNumber = phoneNumber.replace(/[^0-9]/g, '');
                        if(cleanedPhoneNumber.length <= 10)
                          {
                            set_emergency_phone_error('');
                              // Format the phone number as 510-628-8022
                        let formattedPhoneNumber = '';
                        for (let i = 0; i < cleanedPhoneNumber.length; i++) {
                          if (i === 3 || i === 6) {
                            formattedPhoneNumber += '-';
                          }
                          formattedPhoneNumber += cleanedPhoneNumber[i];
                        }
                        set_emergency_phone(formattedPhoneNumber);
                        set_emergency_phone_error('');
                          }
                          else
                          {
                            set_emergency_phone(cleanedPhoneNumber)
                            set_emergency_phone_error("Phone Number Maximum Length Should be 10 Digit ")
                          }
                      }}
                    />
                    {
                      errors?.emergency_phone && (<><span style={{ color: "red" }}>{errors?.emergency_phone[0]}</span></>)
                     || emergencyphoneerror && <><span style={{ color: "red" }}>{emergencyphoneerror}</span></>
                    }
                  </div>
                </div>
                <div className="row">
                  {/* Emergency Relationship */}
                  <div className="col-3">
                    <div className="input-box">
                      <label>
                        Relationship<i style={{ color: "red" }}>*</i>
                      </label>
                      <br></br>
                      <Select
                        style={{ width: "100%", height: "40px" }}
                        value={emergency_relationship === 'null' ? '' : emergency_relationship}
                        onChange={(value) => set_emergency_relationship(value)}
                        placeholder="--Select Relationship--"
                      >
                        <Select.Option value="father">Father</Select.Option>
                        <Select.Option value="mother">Mother</Select.Option>
                        <Select.Option value="brother">Brother</Select.Option>
                        <Select.Option value="sister">Sister</Select.Option>
                        <Select.Option value="other">Other</Select.Option>
                      </Select>
                      {errors?.emergency_relationship && (
                    <>
                      <span style={{ color: "red" }}>{errors?.emergency_relationship[0]}</span>
                    </>
                  )}
                    </div>
                  </div>
                </div>
              </div>
              {/* Emergency Address */}
            </div>
            <div>
              <label className="cus-label">
                Emergency Mailing Address<i style={{ color: "red" }}>*</i>
              </label>
              <div className="row">
                {/* Emergency country */}
                <div className="col-3">
                  <div className="input-box">
                    <label htmlFor="country">
                      Country<i style={{ color: "red" }}>*</i>
                    </label>
                    <CountryDropdown
                      style={{ width: "100%", height: "40px" }}
                      placeholder="--Select Country--"
                      value={emergency_address_country === 'null' ? '' : emergency_address_country}
                      onChange={(value) => set_emergency_address_country(value)}
                      className="ant-input css-dev-only-do-not-override-djtmh8 ant-input-outlined drop-box"
                    />
                    {errors?.emergency_address_country && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.emergency_address_country[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>

                {/* Emergency city */}
                <div className="col-3">
                  <div className="input-box">
                    <label htmlFor="email">
                      City<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      placeholder="City"
                      value={emergency_address_city === 'null' ? '' : emergency_address_city}
                      onChange={(e) => set_emergency_address_city(e.target.value)}
                    />
                    {errors?.emergency_address_city && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.emergency_address_city[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                {/* Emergency state */}
                <div className="col-3">
                  <div className="input-box">
                    <label htmlFor="email">
                      State<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      placeholder="State"
                      value={emergency_address_state === 'null' ? '' : emergency_address_state}
                      onChange={(e) => set_emergency_address_state(e.target.value)}
                    />
                    {errors?.emergency_address_state && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.emergency_address_state[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                {/* Emergency Postal Code */}
                <div className="col-3">
                  <div className="input-box">
                    <label htmlFor="email">
                      Zip / Postal Code<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      placeholder="Zip/Postal"
                      value={emergency_address_postal === 'null' ? '' : emergency_address_postal}
                      onChange={(e) =>
                        set_emergency_address_postal(e.target.value)
                      }
                      keyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                    {errors?.emergency_address_postal && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.emergency_address_postal[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* Emergency Address 1*/}
              <div className="row">
                <div className="col-6">
                  <div className="input-box">
                    <label>
                      Address Field 1<i style={{ color: "red" }}>*</i>
                    </label>
                    <Input
                      placeholder="Address"
                      value={emergency_address1 === 'null' ? '' : emergency_address1}
                      onChange={(e) => set_emergency_address1(e.target.value)}
                    />
                    {errors?.emergency_address1 && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.emergency_address1[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
                {/* Emergency Address 2*/}
                <div className="col-6" >
                  <div className="input-box">
                    <label>
                      Address Field 2
                    </label>
                    <Input
                      placeholder="Address"
                      value={emergency_address2 === 'null' ? '' : emergency_address2}
                      onChange={(e) => set_emergency_address2(e.target.value)}
                    />
                    {errors?.emergency_address2 && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.emergency_address2[0]}
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <br />
            <hr />
            <br />
            <br />
            <div className="row">
              {/* programme / Degree intended */}
              <div className="col-3">
                <div className="input-box">
                  <label>
                    Programme / Degree Intended
                    <i style={{ color: "red" }}>*</i>
                  </label>
                  <Select
                    placeholder="--Select programme--"
                    style={{ width: "100%", height: "40px" }}
                    value={programme_intended}
                    onChange={(value) => set_programme_intended(value)}
                  >
                    {programme_list.map((item) => {
                      if (item.status === 1 && item.soft_delete === 0) {
                        return (
                          <>
                            <Select.Option value={item.id}>
                              {item.title}
                            </Select.Option>
                          </>
                        );
                      }
                    })}
                  </Select>
                  {errors?.programme_intended && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.programme_intended[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* School Decision */}
              <div className="col-3">
                <div className="input-box">
                  <label>
                    School Decision<i style={{ color: "red" }}>*</i>
                  </label>
                  <Select
                    placeholder="--Select Decision--"
                    style={{ width: "100%", height: "40px" }}
                    value={school_decision}
                    onChange={(value) => set_school_decision(value)}
                  >
                    <Select.Option value="Select School Decision">
                      Select School Decision
                    </Select.Option>
                    <Select.Option value="Regular Acceptance">
                      Regular Acceptance
                    </Select.Option>
                    <Select.Option value="Condition Acceptance">
                      Condition Acceptance
                    </Select.Option>
                    <Select.Option value="Deferred">Deferred</Select.Option>
                    <Select.Option value="Denied">Denied</Select.Option>
                    <Select.Option value="Waitlisted">
                      Waitlisted
                    </Select.Option>
                    <Select.Option value="Enrolled">Enrolled</Select.Option>
                  </Select>
                  {errors?.school_decision && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.school_decision[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* Application Checklist */}
              <div className="col-3">
                <div className="input-box">
                  <label>
                    Application Checklist<i style={{ color: "red" }}>*</i>
                  </label>
                  <Select
                    placeholder="--Application Checklist--"
                    style={{ width: "100%", height: "40px" }}
                    onChange={(value) => set_application_checklist(value)}
                    value={application_checklist}
                  >
                    <Select.Option value="Awaiting">Awaiting</Select.Option>
                    <Select.Option value="requested">requested</Select.Option>
                    <Select.Option value="Waived">Waived</Select.Option>
                    <Select.Option value="Completed">Completed</Select.Option>
                  </Select>
                  {errors?.application_checklist && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.application_checklist[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* Financial Aid */}
              <div className="col-3">
                <div className="input-box">
                  <label>
                    Financial Aid<i style={{ color: "red" }}>*</i>
                  </label>
                  <Select
                    style={{ width: "100%", height: "40px" }}
                    placeholder="--Financial Aid--"
                    onChange={(value) => set_financial_aid(value)}
                    value={financial_aid}
                  >
                    <Select.Option value="Yes">Yes</Select.Option>
                    <Select.Option value="No">No</Select.Option>
                  </Select>
                  {errors?.financial_aid && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.financial_aid[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div>
              <br />
              <br />
              <hr />
              <br />
              <div className="theme-content-left-head">
                <h3>Document </h3>
              </div>
              <br />
            </div>


            <div className="row">
              {/* Hight School diploma and transcripts */}
              <div className="col-3">
                <div className="input-box" style={{ position: "relative" }}>
                  <label>
                    Enrollments Agreements<i style={{ color: "red" }}>*</i>
                  </label>

                  <label className="upload-box upload-box-2">
                    <input
                      ref={studentEnrollImage}
                      type="file"
                      id="upload-enrollments-images"

                      style={{ height: "0px", overflow: "hidden" }}
                      onChange={(e) =>
                        handleImageChange(e, "enrollments_agreements")
                      }
                    />
                    <CloudUploadOutlined />
                    Upload

                  </label>

                  {enrollments_agreements ? (
                    <>
                      <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                        <Button onClick={() => window.open(URL.createObjectURL(enrollments_agreements))}>Preview</Button>
                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "enrollments_agreements")}
                        >
                          <CloseOutlined />
                        </button>
                      </div>

                    </>
                  ) : (
                    <>
                      {api_enrollments_agreements && (
                        <>
                          <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                            <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_enrollments_agreements)}>Preview</Button>

                          </div>
                        </>
                      )}
                    </>
                  )}

                  {errors?.enrollments_agreements && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.enrollments_agreements[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="col-3">
                <div className="input-box" style={{ position: "relative" }}>
                  <label style={{ textTransform: "none" }}>
                    High School Diploma and Transcripts
                    <i style={{ color: "red" }}>*</i>
                  </label>
                  <br></br>
                  <label className="upload-box upload-box-2">
                    <input
                      ref={studentHighSchoolDiplomaImage}
                      type="file"
                      id="upload-high-school-images"
                      style={{ height: "0px", overflow: "hidden" }}
                      onChange={(e) =>
                        handleImageChange(
                          e,
                          "high_school_diploma_and_transcripts"
                        )
                      }
                    />
                    <CloudUploadOutlined />
                    Upload

                  </label>
                  {high_school_diploma_and_transcripts ? (
                    <>
                      <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                        <Button onClick={() => window.open(URL.createObjectURL(high_school_diploma_and_transcripts))}>Preview</Button>
                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "high_school_diploma_and_transcripts")}
                        >
                          <CloseOutlined />
                        </button>
                      </div>

                    </>
                  ) : (
                    <>
                      {api_high_school_diploma_and_transcripts && (
                        <>
                          <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                            <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_high_school_diploma_and_transcripts)}>Preview</Button>

                          </div>
                        </>
                      )}
                    </>
                  )}

                  {errors?.high_school_diploma_and_transcripts && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.high_school_diploma_and_transcripts[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* Transcripts from higher educational */}
              <div className="col-3">
                <div className="input-box" style={{ position: "relative" }}>
                  <label>
                    Transcripts From Higher Educational

                  </label>
                  <br></br>
                  <label className="upload-box upload-box-2">
                    {" "}
                    <input
                      ref={studentTranscriptsHigherEducationalImage}
                      type="file"
                      id="upload-higher-education-images"
                      style={{ height: "0px", overflow: "hidden" }}
                      onChange={(e) =>
                        handleImageChange(
                          e,
                          "transcripts_from_higher_educational"
                        )
                      }
                    />{" "}
                    <CloudUploadOutlined />
                    Upload

                  </label>
                  {transcripts_from_higher_educational ? (
                    <>
                      <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                        <Button onClick={() => window.open(URL.createObjectURL(transcripts_from_higher_educational))}>Preview</Button>
                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "transcripts_from_higher_educational")}
                        >
                          <CloseOutlined />
                        </button>
                      </div>

                    </>
                  ) : (
                    <>
                      {api_transcripts_from_higher_educational && (
                        <>
                          <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                            <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_transcripts_from_higher_educational)}>Preview</Button>

                          </div>
                        </>
                      )}
                    </>
                  )}

                  {errors?.transcripts_from_higher_educational && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.transcripts_from_higher_educational[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* Resume */}
              <div className="col-3">
                <div className="input-box" style={{ position: "relative" }}>
                  <label>
                    Resume
                  </label>
                  <br></br>
                  <label className="upload-box upload-box-2">
                    {" "}
                    <input
                    ref={studentResumeImage}
                      type="file"
                      id="upload-resume-images"
                      style={{ height: "0px", overflow: "hidden" }}
                      onChange={(e) => handleImageChange(e, "resume")}
                    />{" "}
                    <CloudUploadOutlined />
                    Upload

                  </label>
                  {resume ? (
                    <>
                      <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                        <Button onClick={() => window.open(URL.createObjectURL(resume))}>Preview</Button>
                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "resume")}
                        >
                          <CloseOutlined />
                        </button>
                      </div>

                    </>
                  ) : (
                    <>
                      {api_resume && (
                        <>
                          <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                            <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_resume)}>Preview</Button>

                          </div>
                        </>
                      )}
                    </>
                  )}

                  {errors?.resume && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.resume[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              {/* Photocopy of passpart or equivaient documents */}
              <div className="col-3">
                <div className="input-box" style={{ position: "relative" }}>
                  <label style={{ textTransform: "none" }}>
                    Photocopy of Passpart or Equivaient Documents{" "}
                  </label>
                  <br></br>
                  <label className="upload-box upload-box-2">
                    {" "}
                    <input
                     ref={studentPasspartImage}
                      type="file"
                      id="upload-passport-images"

                      style={{ height: "0px", overflow: "hidden" }}
                      onChange={(e) =>
                        handleImageChange(
                          e,
                          "photocopy_of_passport_or_equivalent_documents"
                        )
                      }
                    />{" "}
                    <CloudUploadOutlined />
                    Upload
                  </label>
                  {photocopy_of_passport_or_equivalent_documents ? (
                    <>
                      <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                        <Button onClick={() => window.open(URL.createObjectURL(photocopy_of_passport_or_equivalent_documents))}>Preview</Button>
                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "photocopy_of_passport_or_equivalent_documents")}
                        >
                          <CloseOutlined />
                        </button>
                      </div>

                    </>
                  ) : (
                    <>
                      {api_photocopy_of_passport_or_equivalent_documents && (
                        <>
                          <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                            <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_photocopy_of_passport_or_equivalent_documents)}>Preview</Button>

                          </div>
                        </>
                      )}
                    </>
                  )}
                  {errors?.photocopy_of_passport_or_equivalent_documents && (
                    <>
                      <span style={{ color: "red" }}>
                        {
                          errors
                            ?.photocopy_of_passport_or_equivalent_documents[0]
                        }
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* Proof of english */}
              <div className="col-3">
                <div className="input-box" style={{ position: "relative" }}>
                  <label style={{ textTransform: "none" }}>
                    Proof of English
                  </label>
                  <br></br>
                  <label className="upload-box upload-box-2">
                    {" "}
                    <input
                      ref={studentProofEnglishImage}
                      type="file"
                      id="upload-english-images"
                      style={{ height: "0px", overflow: "hidden" }}
                      onChange={(e) =>
                        handleImageChange(e, "proof_of_english")
                      }
                    />{" "}
                    <CloudUploadOutlined />
                    Upload

                  </label>
                  {proof_of_english ? (
                    <>
                      <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                        <Button onClick={() => window.open(URL.createObjectURL(proof_of_english))}>Preview</Button>
                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "proof_of_english")}
                        >
                          <CloseOutlined />
                        </button>
                      </div>

                    </>
                  ) : (
                    <>
                      {api_proof_of_english && (
                        <>
                          <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                            <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_proof_of_english)}>Preview</Button>

                          </div>
                        </>
                      )}
                    </>
                  )}

                  {errors?.proof_of_english && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.proof_of_english[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>

              {/* Essay Explaining interest */}
              <div className="col-3">
                <div className="input-box" style={{ position: "relative" }}>
                  <label>
                    Essay Explaining interest

                  </label>
                  <br></br>
                  <label className="upload-box upload-box-2">
                    {" "}
                    <input
                      ref={studentEssayExplainingImage}
                      type="file"
                      id="upload-essay-images"
                      style={{ height: "0px", overflow: "hidden" }}
                      onChange={(e) =>
                        handleImageChange(e, "essay_explaining_interest")
                      }
                    />{" "}
                    <CloudUploadOutlined />
                    Upload

                  </label>
                  {essay_explaining_interest ? (
                    <>
                      <div className="image-box" style={{ width: "145px", height: "60px", padding: "11px", margin: "9px" }}>
                        <Button onClick={() => window.open(URL.createObjectURL(essay_explaining_interest))}>Preview</Button>
                        <button className="image-remove" style={{ top: "5px", bottom: "auto", left: "auto", right: "15px" }} onClick={(e) => handleRemoveImage(e, "essay_explaining_interest")}
                        >
                          <CloseOutlined />
                        </button>
                      </div>

                    </>
                  ) : (
                    <>
                      {api_essay_explaining_interest && (
                        <>
                          <div className="image-box" style={{ width: "105px", height: "60px", padding: "11px", margin: "9px" }}>
                            <Button onClick={() => window.open(SIS_STORAGE + "/enrollments/" + api_essay_explaining_interest)}>Preview</Button>
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {errors?.essay_explaining_interest && (
                    <>
                      <span style={{ color: "red" }}>
                        {errors?.essay_explaining_interest[0]}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-3">
                <div className="input-box">
                  <label>
                    Student Status<i style={{ color: "red" }}>*</i>
                  </label>
                  <br></br>
                  <Select
                    placeholder="Select Status"
                    style={{ width: "100%" }}
                    value={parseInt(student_status)}
                    onChange={(value) => set_student_status(value)}
                  >
                    {student_status_check == 2 && student_status_check && <Select.Option value={2}>
                    Enrolled
                    </Select.Option>}
                    <Select.Option value={1}>
                     Ready for Programme registration
                    </Select.Option>
                  </Select>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="input-box">
                  {loader ? (
                    <>
                      <Button type="primary">
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: "12px",
                                color: "#fff",
                                marginRight: "5px",
                              }}
                            />
                          }
                        />{" "}
                        Save
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button onClick={UPDATE_API} type="primary">
                        Save
                      </Button>{" "}
                      <Button
                        type="primary"
                        onClick={() => navigate("/add-enrollment/" + id)}
                      >
                        Register For Program
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </div>


          </div>
        </>
      )}
    </div>
  );
};

export default EditStudent;
