import { Button, Table, Tooltip } from "antd";
import SisLoader from "../../../widgets/loader";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../../../config/config";
import { FilePdfOutlined } from "@ant-design/icons";
import { STUDENT_BY_SEMESTER_ATTENDANCE_REPORT, sisDateFormat } from "../../../apis/apis";



const AttendanceGenerateReportTable = ({semester, student , onError }) => {
    const [loader, set_loader] = useState(false);
    const [attendance_report_list, set_attendance_report_list] = useState([]);
    const [errors, set_errors] = useState([]);


    const STUDENT_LIST_BY_SEMESTER_ATTENDANCE_REPORT = async(sem_id) =>{
      set_attendance_report_list([]);
      set_loader(true);
      const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
      const FORM_DATA = new FormData();
      FORM_DATA.append('token', USER_DATA.token);
      FORM_DATA.append('semester_id', semester);
      FORM_DATA.append('student_id', student);
      const API_RESPONSE = await STUDENT_BY_SEMESTER_ATTENDANCE_REPORT(FORM_DATA);
      if (API_RESPONSE?.data?.status) {
        console.log(API_RESPONSE?.data);
           set_attendance_report_list(API_RESPONSE?.data?.reports);
          set_loader(false);
      }
      else {
          set_errors(API_RESPONSE?.data?.errors)
          onError(API_RESPONSE?.data?.errors); // Pass errors to the parent component
          set_loader(false);
      }
      set_loader(false);
  }
  
//   useEffect(() => {
//     STUDENT_LIST_BY_SEMESTER_ATTENDANCE_REPORT();
// }, [semester,student]);


const columns = [
  {
    title: 'Courses',
    render: (text, record) => {
      return (
        <>
          {record?.course?.title}{" ( "}{record?.course?.code}{" ) "}
        </>
      );
    },
    sorter: (a, b) => {
      if (a.course?.title < b.course?.title) return -1;
      if (a.course?.title > b.course?.title) return 1;
      return 0;
    },
  },
  {
    title: 'Date',
    render: (text, record) => {
      return (
        <>
          {sisDateFormat(record?.attendance?.selected_date)}
        </>
      );
    },
  },
  {
    title: 'Attendance Category',
    render: (text, record) => {
      return (
        <>
          {record?.attendance_category?.title}
        </>
      );
    },
    sorter: (a, b) => {
      if (a.attendance_category?.title < b.attendance_category?.title) return -1;
      if (a.attendance_category?.title > b.attendance_category?.title) return 1;
      return 0;
    },
  },
  {
    title: 'Reason',
    render: (text, record) => {
      return (
        <>
          {record?.reason?.reason_title}
        </>
      );
    },
    sorter: (a, b) => {
      if (a.reason?.reason_title < b.reason?.reason_title) return -1;
      if (a.reason?.reason_title > b.reason?.reason_title) return 1;
      return 0;
    },
  },
  {
    title: 'Comment',
    render: (text, record) => {
      return (
        <>
          {record?.attendance?.comment}
        </>
      );
    },
  },
];


    return(
        <>
           <div className="row">
                <div className="col-3" style={{ marginTop: "20px", marginLeft:"8px" }}>
                    <div className="input-box" style={{ width: "100%" }}>
                        <Button style={{ width: "100%", height: "45px" }} type='primary' onClick={STUDENT_LIST_BY_SEMESTER_ATTENDANCE_REPORT}>Generate Report</Button>
                    </div>
                </div>
            </div>
        <br/>
        <br/>
        {loader ? <SisLoader /> : 
        <>
        { attendance_report_list.length > 0 ?
          <>
            <div style={{display:"inline-block", float:"right", marginRight:"10px"}}>
                    {/* PDF Download button  */}
                    <Tooltip title="Download Attendance Report PDF">
                        <Button type='primary' onClick={() => window.open(BACKEND_URL + '/attendance-report-pdf/' + JSON.parse(localStorage.getItem('sis_user_data')).token +'/'+ student +'/'+ semester)} ghost style={{ marginLeft: "5px" }}><FilePdfOutlined /></Button>
                    </Tooltip>
                </div>
        <Table columns={columns} dataSource={attendance_report_list} />
        </>
        :
        <>
         <p style={{textAlign:"center", color:"red"}}>
          No Attendance Report Found.</p>
        </>
        }
        </>
        }
        </>
    )
}

export default AttendanceGenerateReportTable;